import { Table as AntdTable, TableProps } from 'antd'
import { isNumber } from 'lodash'
import { ReactElement } from 'react'
import styled from 'styled-components'

export const TABLE_DEFAULT_PAGE_SIZE = 20 // default value in schema for lists
const Wrapper = styled.div``

/**
 * How to use:
 * - set width for every column (add always 32px for padding), one column do not set width for correct responsive
 *
 * - Show separated loading table/skeleton - table has to be rendered with full data otherwise defaultExpand doesn't work.
 * - calculate width of columns and set scroll.x; If no width for column, use default value
 */
export const Table = <RecordType extends {}>(props: TableProps<RecordType>): ReactElement => {
	const key = props.loading ? '-loading' : ''
	let scrollX = 0
	props.columns?.map((v) => {
		// 150 is default value
		scrollX += (isNumber(v.width) ? v.width : 0) || 150
	})

	return (
		<Wrapper>
			<AntdTable key={'table' + key} scroll={{ x: scrollX }} {...props} />
		</Wrapper>
	)
}
