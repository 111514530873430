import { Divider, Layout } from 'antd'
import { AcceptTocAgreementModal } from 'components/functional/account/agreements/AcceptTocAgreementModal'
import { Breadcrumb } from 'components/layout/Breadcrumb'
import { Footer } from 'components/layout/footer/Footer'
import { Header } from 'components/layout/header/Header'
import { useSelector } from 'hooks/useSelector'
import { lazyComponent } from 'modules/helpers/lazyComponent'
import { useIsUserLogged } from 'modules/keycloak'
import { themeColor } from 'modules/styles/themeStyles'
import { PageContent } from 'modules/ui/PageContent'
import { SpaceElement } from 'modules/ui/SpaceElement'
import { FC } from 'react'
import styled from 'styled-components'

const BlockTradingBox = lazyComponent(() => import('components/functional/account/BlockTradingBox'), <></>)
const AcceptTocAgreementBanner = lazyComponent(() => import('components/functional/account/agreements/AcceptTocAgreementBanner'), <></>)
export const MIN_HEIGHT_PAGE = '70vh'

const LayoutStyled = styled(Layout)<{ isForbiddenContent: boolean }>`
	margin: 0;

	.ant-layout-header {
		height: auto;
	}
	&,
	.ant-layout-header,
	.ant-layout-footer,
	.ant-layout {
		background-color: transparent;
		padding: 0;
	}

	.content {
		margin: 25px 0;
		min-height: ${({ isForbiddenContent }) => (isForbiddenContent ? '0' : MIN_HEIGHT_PAGE)};
	}
`

export const PrivateRouteLayout: FC = ({ children }) => {
	const { isUserLogged } = useIsUserLogged()
	const { isForbidden } = useSelector((state) => state.account)
	const isForbiddenContent = !isUserLogged || isForbidden

	return (
		<LayoutStyled isForbiddenContent={isForbiddenContent}>
			{!isForbiddenContent ? (
				<>
					<Layout.Header>
						<PageContent width={'xl'} background={'#fff'}>
							<Header />
						</PageContent>
						<Divider style={{ margin: 0 }} />
						<BlockTradingBox />
						<AcceptTocAgreementBanner />
						<AcceptTocAgreementModal />
					</Layout.Header>
					<Layout.Content>
						<PageContent width={'xl'}>
							<SpaceElement padding={'15px'} />
							<Breadcrumb />
							<SpaceElement padding={'1%'} />
						</PageContent>
					</Layout.Content>
				</>
			) : null}
			<Layout.Content className={'content'}>{children}</Layout.Content>
			<SpaceElement padding={'5%'} />
			<Layout.Footer>
				<Divider style={{ margin: 0 }} />
				<PageContent width={'fullWidth'} background={themeColor.grayWhite}>
					<Footer />
				</PageContent>
			</Layout.Footer>
		</LayoutStyled>
	)
}
