import { themeColor, themeSize, themeWeight } from 'modules/styles/themeStyles'
import { css } from 'styled-components'

const table = css`
	.ant-table-wrapper .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
		text-align: inherit;
		font-weight: ${themeWeight.bold};
	}

	// Header with 'colSpan'
	.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
		// column header which is 'colSpan' change default align
		text-align: inherit;

		// 'colSpan' header doesn't have border. Copy paste from ant styles
		&,
		&:hover {
			&:not(:last-child):before {
				position: absolute;
				top: 50%;
				right: 0;
				width: 1px;
				height: 1.6em;
				background-color: rgba(0, 0, 0, 0.06) !important;
				transform: translateY(-50%);
				transition: background-color 0.3s;
				content: '';
			}
		}
	}

	// filter list on table
	.ant-table-filter-dropdown {
		font-size: 12px;

		.ant-dropdown-menu-item {
			font-size: inherit;
		}
	}
`
const divider = css`
	.ant-divider {
		border-width: 2px;
	}
`
const btn = css`
	.ant-btn {
		font-weight: ${themeWeight.extraBold};

		&.ant-btn-ghost {
			border-color: ${themeColor.grayLight};
		}
		&.ant-btn-sm {
			font-size: ${themeSize.sm};
		}
	}
`
const typography = css`
	.ant-typography {
		font-size: inherit;
		color: inherit;
	}
`
const space = css`
	.ant-space {
		font-size: inherit;
		display: flex;
	}
`
const spin = css`
	*[class^='ant-spin'],
	*[class*=' ant-spin'] {
		font-size: inherit;
	}
`
const statistic = css`
	.ant-statistic {
		display: inline-block;

		&,
		.ant-statistic-content {
			font-size: inherit;
		}
	}
`
const form = css`
	// move required '*' after label not before
	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		position: absolute;
		left: 103%;
	}
`
export const antdGlobal = css`
	${divider};
	${table};
	${form};
	${space};
	${statistic};
	${spin};
	${typography};
	${btn};
`
