import { PageHeader, PageHeaderProps } from '@ant-design/pro-layout'
import Title, { TitleProps } from 'antd/es/typography/Title'
import { themeSize } from 'modules/styles/themeStyles'
import { Sticky, STICKY_CLASS_NAME } from 'modules/ui/Sticky'
import { FC } from 'react'
import styled from 'styled-components'

const StickyStyled = styled(Sticky)`
	padding: 20px 0 0 0; // whiteBox padding-top
	box-shadow: 0 10px 15px 0 #fff;
	margin: 0 0 15px 0; // shadow space

	.ant-page-header-heading-title {
		font-size: ${themeSize.heading1};
		line-height: inherit;
	}
	.ant-page-header {
		padding: 0;
	}
`

type IProps = JSX.IntrinsicElements['div'] & {
	header?: PageHeaderProps
	sticky?: boolean
	titleProps?: TitleProps
}
export const WhiteBox: FC<IProps> = ({ header, sticky, titleProps, children, ...props }) => (
	<div
		{...props}
		style={{
			padding: header || titleProps ? '0 30px 30px 30px' : '20px 30px 30px 30px',
			borderRadius: '10px',
			backgroundColor: '#fff',
			boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
			...props.style,
		}}
	>
		{header ? (
			<StickyStyled sticky={sticky} className={sticky ? STICKY_CLASS_NAME : ''}>
				<PageHeader {...header} />
			</StickyStyled>
		) : null}
		{titleProps ? (
			<StickyStyled sticky={sticky} className={sticky ? STICKY_CLASS_NAME : ''}>
				<Title {...titleProps} />
			</StickyStyled>
		) : null}
		{children}
	</div>
)
