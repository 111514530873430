import { DetailsLink } from 'components/functional/trade/common/DetailsLink'
import { TradeDetails } from 'components/functional/trade/tradeDetails/TradeDetails'
import { IExpandReturned } from 'modules/table/useExpandRowTable'
import { TradeProgressSteps } from 'modules/ui/TradeProgressSteps'
import { FC } from 'react'
import { TradeResponse } from 'store/slices/tradesSlice'
import styled from 'styled-components'

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`

interface IProps {
	item: TradeResponse
	expandDetails: IExpandReturned
}
export const ExpandedRow: FC<IProps> = ({ item, expandDetails }) => {
	const showTradeDetails = expandDetails.expandedRowKeys[0] === item.id
	return (
		<>
			<Wrapper>
				{item.status && <TradeProgressSteps status={item.status} />}
				<DetailsLink onClick={expandDetails.onExpand(item.id)} arrowToBottom={showTradeDetails} />
			</Wrapper>
			{showTradeDetails ? <TradeDetails item={item} /> : null}
		</>
	)
}
