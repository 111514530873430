import { MenuOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { menuItems } from 'components/layout/header/menu/menuItems'
import { MobileView } from 'modules/styles/mixins/MobileView'
import { themeColor, themeSize, themeWeight } from 'modules/styles/themeStyles'
import { FC } from 'react'
import styled, { css } from 'styled-components'

const MenuStyled = styled(Menu)`
	justify-content: flex-end;
	align-items: center;
	min-width: 500px;
	border: none;
	font-size: ${themeSize.base};
	font-weight: ${themeWeight.extraBold};

	${MobileView(
		css`
			display: none !important;
		`,
		{ screen: 'md' },
	)};

	// hamburger icon on mobile screen
	.ant-menu-overflow-item-rest {
		svg {
			font-size: 2em;
			color: ${themeColor.blue};
			cursor: pointer;
		}
	}

	.ant-menu-item:after,
	.ant-menu-submenu:after {
		// remove ant underline
		display: none;
	}
`

export const MenuNav: FC = () => {
	return <MenuStyled mode={'horizontal'} selectable={false} overflowedIndicator={<MenuOutlined />} forceSubMenuRender items={menuItems()} />
}
