import { ValidateMessages } from 'rc-field-form/lib/interface'

export const validateMessages: ValidateMessages = {
	required: 'This field is required',
	string: {
		min: 'Field should be longer',
		max: 'Field should be shorter',
		range: 'Field must be between ${min} and ${max} characters',
	},
	types: {
		email: 'It is not a valid email',
	},
}

export const phoneValidation = (): object => ({
	validator(_, value) {
		if (!value) {
			return Promise.resolve()
		}
		return /^[0-9 ()+-]{9,19}$/.test(value) ? Promise.resolve() : Promise.reject(new Error('It is not a valid phone number'))
	},
})
