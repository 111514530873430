import axios, { AxiosPromise } from 'axios'
import { SessionResponse, UserInfoResponse } from './types'

const APP_NAME = 'frontoffice'
const CLIENT_ID = `fx-${APP_NAME}-client`
const BASE_PATH = `/realms/${APP_NAME}/protocol/openid-connect`

export const KEYCLOAK_LOGIN_URL = `${BASE_PATH}/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=`
export const KEYCLOAK_REGISTER_URL = `${BASE_PATH}/registrations?response_type=code&client_id=${CLIENT_ID}&redirect_uri=`
export const KEYCLOAK_TOKEN_URL = `${BASE_PATH}/token`
export const KEYCLOAK_LOGOUT_URL = `${BASE_PATH}/logout`
export const KEYCLOAK_USER_INFO = `${BASE_PATH}/userinfo`

export interface IGetTokenParams {
	keycloakDomain: string
	code: string
	redirect_uri: string
}
export const getToken = ({ keycloakDomain, code, redirect_uri }: IGetTokenParams): AxiosPromise<SessionResponse> => {
	return axios.post(
		`${keycloakDomain}${KEYCLOAK_TOKEN_URL}`,
		new URLSearchParams({
			client_id: CLIENT_ID,
			grant_type: 'authorization_code',
			code,
			redirect_uri,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
	)
}

export interface ILogoutParams {
	keycloakDomain: string
	refreshToken?: string
}
export const logout = ({ keycloakDomain, refreshToken = '' }: ILogoutParams): Promise<void> => {
	return refreshToken
		? axios.post(
				`${keycloakDomain}${KEYCLOAK_LOGOUT_URL}`,
				new URLSearchParams({
					client_id: CLIENT_ID,
					refresh_token: refreshToken,
				}),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				},
		  )
		: Promise.reject()
}

export interface IRefreshTokenParams {
	keycloakDomain: string
	refreshToken?: string
}
export const refreshToken = ({ refreshToken = '', keycloakDomain }: IRefreshTokenParams): AxiosPromise<SessionResponse> => {
	return axios.post(
		`${keycloakDomain}${KEYCLOAK_TOKEN_URL}`,
		new URLSearchParams({
			client_id: CLIENT_ID,
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
	)
}

export interface IUserInfoParams {
	keycloakDomain: string
	accessToken?: string
}
export const getUserInfo = ({ accessToken = '', keycloakDomain }: IUserInfoParams): AxiosPromise<UserInfoResponse> => {
	return axios.post(
		`${keycloakDomain}${KEYCLOAK_USER_INFO}`,
		new URLSearchParams({
			access_token: accessToken,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
	)
}

export interface IRegisterParams {
	keycloakDomain: string
	loginPage: string
}
export const getRegisterUrl = ({ keycloakDomain, loginPage }: IRegisterParams): string => {
	return `${keycloakDomain}${KEYCLOAK_REGISTER_URL}${loginPage}`
}
