import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb as AntBreadcrumb, BreadcrumbProps, Typography } from 'antd'
import { PATH } from 'components/pages/Paths'
import { useSelector } from 'hooks/useSelector'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const { Text } = Typography

const itemCss = css`
	white-space: nowrap;
`
const LastItem = styled(Text)`
	${itemCss};
`
const LinkStyled = styled(Link)`
	${itemCss};
`

export const Breadcrumb: FC = () => {
	const breadcrumb = useSelector((state) => state.breadcrumb)

	const items: BreadcrumbProps['items'] = [
		{
			title: (
				<Link to={PATH.DASHBOARD.path}>
					<HomeOutlined />
				</Link>
			),
		},
		...breadcrumb.map((item, index) => {
			const last = breadcrumb.length - 1 === index
			return {
				key: item.path,
				title: last ? <LastItem ellipsis>{item.breadcrumb}</LastItem> : <LinkStyled to={item.path}>{item.breadcrumb}</LinkStyled>,
			}
		}),
	]

	return <AntBreadcrumb items={items} />
}
