import { css, FlattenInterpolation, FlattenSimpleInterpolation } from 'styled-components'
import { IThemeScreen, themeScreen } from '../themeStyles'

interface IProps {
	screen: keyof IThemeScreen
}
export const MobileView = (
	styles: FlattenSimpleInterpolation | FlattenInterpolation<any>,
	params: IProps = { screen: 'sm' },
): FlattenInterpolation<any> => {
	return css`
		@media (max-width: ${themeScreen[params.screen]}) {
			${styles};
		}
	`
}
export const DesktopView = (
	styles: FlattenSimpleInterpolation | FlattenInterpolation<any>,
	params: IProps = { screen: 'sm' },
): FlattenInterpolation<any> => css`
	@media (min-width: ${themeScreen[params.screen]}) {
		${styles};
	}
`
