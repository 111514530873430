/**
 * font-size
 */
export interface IThemeSizes {
	mini: string
	sm: string
	base: string
	lg: string
	heading5: string
	heading4: string
	heading3: string
	heading2: string
	heading1: string
}
export const themeSize: { [k in keyof IThemeSizes]: string } = {
	mini: '10px',
	sm: '12px',
	base: '14px',
	lg: '16px',
	heading5: '18px',
	heading4: '20px',
	heading3: '24px',
	heading2: '30px',
	heading1: '38px',
}

/**
 * screen width
 */
export interface IThemeScreen {
	xs: string
	sm: string
	md: string
	lg: string
	xl: string
	xxl: string
	maxContent: string
	midContent: string
	smallContent: string
}
export const themeScreen: { [k in keyof IThemeScreen]: string } = {
	xs: '480px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	xxl: '1600px',

	// Inner content sizes
	maxContent: '1600px', // max content for this app
	midContent: '1100px', // usually used middle content for this app
	smallContent: '850px', // usually used small content for this app
}

/**
 * font-weight
 */
export interface IThemeWeight {
	normal: number
	bold: number
	extraBold: number
}
export const themeWeight: IThemeWeight = {
	normal: 400,
	bold: 500,
	extraBold: 600,
}

/**
 * special colors
 */
export interface IThemeColor {
	blueDark: string
	blue: string
	blueMiddleWhite: string
	green: string
	greenDark: string
	greenLight: string
	greenMiddle: string
	grayBlack: string
	grayDarker: string
	grayDark: string
	gray: string
	grayLight: string
	grayWhite: string
	grayTransparent: string
	blueWhite: string
	blueMiddle: string
	orange: string
	orangeDarker: string
	orangeMiddle: string
	orangeWhite: string
	pink: string
	red: string
	redMiddle: string
	redLight: string
	black: string
}
export const themeColor: IThemeColor = {
	blueDark: '#000066',
	blue: '#6699FF', // @primary-color
	blueMiddle: '#e8f1ff',
	blueMiddleWhite: '#f3f8ff',
	blueWhite: '#f7fbff',
	greenDark: '#07a26e',
	green: '#33CC99',
	greenLight: '#67fc8b',
	greenMiddle: '#c5ffd1',
	grayBlack: '#00000040',
	grayDarker: '#4F4F4E',
	grayDark: '#6B6B7D',
	gray: '#9494A1',
	grayLight: '#CCCCCC',
	grayWhite: '#F2F2F7',
	grayTransparent: '#fafafa',
	orange: '#FFCC00',
	orangeDarker: '#ffe67f',
	orangeMiddle: '#fff5cc',
	orangeWhite: '#fffcf2',
	pink: '#FF0066',
	red: '#ff4d4f',
	redMiddle: '#ff8e90',
	redLight: '#ffc6c7',
	black: '#000',
}
