import { AnyAction, AsyncThunk, CaseReducer, configureStore, Draft, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistCombineReducers, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AsyncThunkDataType } from 'store/common'
import slices, { whitelistPersist } from './slices'

export type HttpErrorMessage = string | string[] | undefined

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	whitelist: whitelistPersist,
	blacklist: [],
}

const persistedReducer = persistCombineReducers(persistConfig, slices)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
})

export const persistor = persistStore(store)
export const dispatch = store.dispatch

export type RootState = ReturnType<typeof store.getState>
export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>

export type AppThunkType = {
	dispatch: TypedDispatch<RootState>
	state: RootState
	/**
	 * api can return different type of error, rejectWithValue is good place to set error in correct type
	 * FX API:
	 *  - standard message is string[] (HttpError['message'])
	 *  - in asyncThunks use always rejectWithValue(err.response?.data?.message)
	 *
	 * OTHER API (eg: KYC):
	 *  - use dedicated type
	 */
	rejectValue: HttpErrorMessage | any
	extra?: unknown
	serializedErrorType?: unknown
	pendingMeta?: unknown
	fulfilledMeta?: unknown
	rejectedMeta?: unknown
}
/*
	Use it as type for createAsyncThunk	eg:
	const myAction: AsyncThunkActionType<..> = createAsyncThunk(..)
 */
export type AsyncThunkActionType<Returned, ThunkArg = AsyncThunkDataType> = AsyncThunk<Draft<Returned>, ThunkArg, AppThunkType>
/*
	Use it to create new reducer(action) in createSlice()
 */
export type CaseReducerType<State, PayloadState = void> = CaseReducer<State, PayloadAction<PayloadState>>
