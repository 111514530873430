import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getUserInfo } from 'modules/keycloak/store/getUserInfo'
import { SessionResponse, UserInfoResponse } from '../api/types'
import { getToken } from '../store/getToken'
import { refreshToken } from '../store/refreshToken'

type CaseReducerType<State, PayloadState> = CaseReducer<State, PayloadAction<PayloadState>>
export type AuthState = {
	lastAuthCode?: string
	session?: SessionResponse
	userInfo?: UserInfoResponse
	/**
	 * isLogoutPending to secure logout & clear store
	 */
	isUserLogoutPending: boolean
	backToUri: string
}

const initialState: AuthState = {
	session: undefined,
	userInfo: undefined,
	isUserLogoutPending: false,
	backToUri: '',
}

type CaseReducers<State> = {
	/**
	 * logout
	 * @isLogoutPending - true for client manual logout action, false if you need automatically redirect to login page
	 */
	logout: CaseReducerType<State, boolean | undefined>
	/**
	 * setBackToUri - set current pathname in Route, while its checking private route & isUserLogged
	 */
	setBackToUri: CaseReducerType<State, string>
}

export const authSlice = createSlice<AuthState, CaseReducers<AuthState>>({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state, { payload }) => ({
			...initialState,
			isUserLogoutPending: payload || state.isUserLogoutPending,
			backToUri: payload ? '' : state.backToUri,
		}),
		setBackToUri: (state, { payload }) => {
			state.backToUri = payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getToken.fulfilled, (state, action) => {
			state.session = action.payload.data
			state.lastAuthCode = action.payload.params.code
		})
		builder.addCase(refreshToken.fulfilled, (state, action) => {
			state.session = action.payload
		})
		builder.addCase(getUserInfo.fulfilled, (state, action) => {
			state.userInfo = action.payload
		})
	},
})

export const { logout, setBackToUri } = authSlice.actions

export default authSlice.reducer
