import { Logo } from 'assets/icon'
import { FooterLink } from 'components/layout/footer/FooterLink'
import { FC } from 'react'
import styled from 'styled-components'

const Contact = styled.div`
	margin: 30px 0 0 0;
`
const Wrapper = styled.div`
	svg {
		font-size: 5em;
		margin: 0 0 20px 0;
	}
`

interface IProps {
	withLogo?: boolean
	withContact?: boolean
}
export const AddressBox: FC<IProps> = ({ withLogo = false, withContact = false }) => {
	return (
		<Wrapper>
			{withLogo ? <Logo /> : null}
			<div>
				Bitclear Aktiengesellschaft
				<br />
				P.O. Box 534
				<br />
				Industriestrasse 26
				<br />
				9491 Ruggell, Liechtenstein
			</div>
			{withContact ? (
				<Contact>
					<FooterLink href="tel:+4233771199">+423 377 11 99</FooterLink>
					<br />
					<FooterLink href="https://www.bitclear.li">www.bitclear.li</FooterLink>
					<br />
					<FooterLink href="mailto:info@bitclear.li">info@bitclear.li</FooterLink>
				</Contact>
			) : null}
		</Wrapper>
	)
}
