import { useSelector } from 'hooks/useSelector'
import { useActionStatus } from 'modules/hooks/useActionStatus'
import { useEffect } from 'react'
import { agreementTocGet } from 'store/asyncThunks/agreementTocGet'
import { dispatch } from 'store/store'

interface IReturned {
	isTocAccepted: boolean
	showTocModal: boolean
	isPending: boolean
}
export const useUserTocAgreements = (): IReturned => {
	const { agreements } = useSelector((state) => state.account)
	const { handleStatus, isPending } = useActionStatus()

	useEffect(() => {
		handleStatus(dispatch(agreementTocGet()))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {
		isPending,
		isTocAccepted: !!agreements.isTocAccepted,
		showTocModal: agreements.showTocModal,
	}
}
