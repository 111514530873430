import { Bfx } from 'assets/icon'
import { ClientInfo } from 'components/layout/header/ClientInfo'
import { HEADER_HEIGHT } from 'components/layout/header/common'
import { ClientProfile } from 'components/layout/header/menu/clientProfile/ClientProfile'
import { MenuNav } from 'components/layout/header/menu/MenuNav'
import { MenuNavMobile } from 'components/layout/header/menu/MenuNavMobile'
import { ENV } from 'config/env'
import { DevInfo } from 'modules/ui/DevInfo'
import { FC } from 'react'
import styled from 'styled-components'

const Logo = styled.a`
	display: flex;
	font-size: 4.3em;
	position: relative;
`

const ClientProfileBox = styled.div`
	margin-left: 10px;
`
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding: 5px 0 10px 0;
	min-height: 75px;
	// do header sticky then use min-height from common
	//min-height: ${HEADER_HEIGHT};
	line-height: normal;
`

export const Header: FC = () => {
	return (
		<Wrapper>
			<Logo href={'/'}>
				<Bfx />
			</Logo>
			<ClientInfo />
			{!ENV.IS_PRODUCTION ? <DevInfo /> : <div style={{ width: '100%' }} />}
			<MenuNav />
			<MenuNavMobile />
			<ClientProfileBox>
				<ClientProfile />
			</ClientProfileBox>
		</Wrapper>
	)
}
