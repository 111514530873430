import { ENV } from 'config/env'

export type LANDING_PATH =
	| 'exchange'
	| 'exchange/glossary'
	| 'exchange/affiliate'
	| 'about-us'
	| 'contact'
	| 'legal-info'
	| 'impressum'
	| 'terms-conditions'
	| 'privacy-policy'
	| 'faq'

export type LANDING_HASH = 'how-it-works'

export const getLandingUrl = (path: LANDING_PATH, hash?: LANDING_HASH): string => {
	const h = hash ? '#' + hash : ''

	return ENV.LANDING_PAGE_URL + '/' + path + h
}
