import { createSlice } from '@reduxjs/toolkit'
import { EnabledCurrencies } from 'modules/helpers/currencies'
import { currenciesGet } from 'store/asyncThunks/currenciesGet'

type State = {
	currencies: EnabledCurrencies
}

const initialState: State = {
	currencies: {
		FIAT: [],
		CRYPTO: [],
		list: [],
	},
}

export const currenciesSlice = createSlice<State, {}>({
	name: 'wallets',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(currenciesGet.fulfilled, (state, action) => {
			state.currencies = action.payload
		})
	},
})

export const {} = currenciesSlice.actions
export default currenciesSlice.reducer
