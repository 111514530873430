import { Button, Col, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import { Orders } from 'assets/icon'
import { KycStatusBannerContent } from 'components/functional/kyc/statusBanner/KycStatusBannerContent'
import { TRADE_PATH } from 'components/pages/fx/trade/TradeRoutes'
import { useUserData } from 'hooks/useUserData'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { cyTag } from 'utils/cyTag'

const newOrderInfo = (
	<>
		Create{' '}
		<Link to={TRADE_PATH.NEW_TRADE.path}>
			<Button type={'primary'} ghost>
				NEW ORDER
			</Button>
		</Link>
	</>
)

export const NoOrdersBox: FC = () => {
	const { isKycCompleted } = useUserData()

	return (
		<>
			<Row gutter={16} align="middle" data-cy={cyTag.ordersInProgress.noOrdersWrapper}>
				<Col>
					<Orders />
				</Col>
				<Col>
					<Title level={5}>You currently have no pending orders</Title>
					{!isKycCompleted ? <KycStatusBannerContent /> : newOrderInfo}
				</Col>
			</Row>
		</>
	)
}
