import { MenuOutlined } from '@ant-design/icons'
import { Menu, Modal } from 'antd'
import { menuItems } from 'components/layout/header/menu/menuItems'
import { useModalFn } from 'modules/hooks/useModalFn'
import { MobileView } from 'modules/styles/mixins/MobileView'
import { themeColor, themeSize } from 'modules/styles/themeStyles'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { FC } from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
	display: none;
	padding: 0 10px 0 0;

	${MobileView(
		css`
			display: inline-block !important;
		`,
		{ screen: 'md' },
	)}

	svg {
		font-size: 2em;
		color: ${themeColor.blue};
		cursor: pointer;
	}
`
const ModalStyled = styled(Modal)`
	top: 5px;

	.ant-modal-body {
		padding-top: 10px;
	}
	.ant-modal-close {
		svg {
			font-size: 2em;
			color: ${themeColor.blue};
		}
	}
`
const MenuStyled = styled(Menu)`
	font-size: ${themeSize.base}!important;
	border: none !important;

	.ant-menu-title-content {
		overflow: visible;
	}
`

export const MenuNavMobile: FC = () => {
	const { showModal, openModal, closeModal } = useModalFn()
	const onItemClick: MenuClickEventHandler = () => {
		closeModal()
	}

	return (
		<Wrapper>
			<MenuOutlined onClick={openModal} />
			<ModalStyled open={showModal} onCancel={closeModal} title={<>&nbsp;</>} footer={null}>
				<MenuStyled
					mode={'inline'}
					selectable={false}
					overflowedIndicator={false}
					forceSubMenuRender
					onClick={onItemClick}
					items={menuItems(true)}
				/>
			</ModalStyled>
		</Wrapper>
	)
}
