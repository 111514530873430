import { clearBreadcrumb, updateBreadcrumb } from 'store/slices/breadcrumbSlice'
import { dispatch } from 'store/store'
import { ObjectKeyType } from 'types'

export type RoutePath = {
	path: string
	breadcrumb?: string
}
export type RoutePaths = ObjectKeyType<RoutePath>

export const setBreadcrumbPath = (routePath: RoutePath): void => {
	dispatch(updateBreadcrumb([routePath]))
}

/**
 * set it on every root path in Routes.tsx
 */
export const clearBreadcrumbPath = (): void => {
	dispatch(clearBreadcrumb())
}
