// KYC - ReviewStatusType

export type KYC_STATUS =
	| 'NewReviewStatus'
	| 'NewPartiallyFilled'
	| 'PartiallyFilled'
	| 'SubmittedForReview'
	| 'CorrectionsRequired'
	| 'CorrectedByAdmin'
	| 'Approved'
	| 'Denied'
	| 'Unknown'

export const kycFriendlyName: { [k in KYC_STATUS]: string } = {
	NewReviewStatus: 'New',
	NewPartiallyFilled: 'Partially filled',
	PartiallyFilled: 'Partially filled',
	SubmittedForReview: 'Waiting for review',
	CorrectionsRequired: 'Corrections Required',
	CorrectedByAdmin: 'Corrected by Admin',
	Approved: 'Approved',
	Denied: 'Denied',
	Unknown: 'Unknown',
}
export const KYC_COMPLETED: KYC_STATUS[] = ['Approved']
export const KYC_NOT_COMPLETED: KYC_STATUS[] = ['Denied']
export const KYC_PENDING: KYC_STATUS[] = [
	'NewReviewStatus',
	'NewPartiallyFilled',
	'PartiallyFilled',
	'SubmittedForReview',
	'CorrectionsRequired',
	'CorrectedByAdmin',
	'Unknown',
]

// ================

export type CLIENT_STATUS = 'REGISTERED' | KYC_STATUS

export const statusFriendlyName: { [k in CLIENT_STATUS]: string } = {
	...kycFriendlyName,
	REGISTERED: 'Registered',
}
export const statusAdminFriendlyName: { [k in CLIENT_STATUS]: string } = {
	...statusFriendlyName,
	NewPartiallyFilled: 'New partially filled',
}

// new status - kyc was initialised | client was registered
export const newClientStatus: CLIENT_STATUS[] = ['REGISTERED', 'NewReviewStatus']
export const clientStatuses: CLIENT_STATUS[] = [
	'NewReviewStatus',
	'NewPartiallyFilled',
	'PartiallyFilled',
	'CorrectionsRequired',
	'CorrectedByAdmin',
]
export const adminStatuses: CLIENT_STATUS[] = ['SubmittedForReview']
export const completed: CLIENT_STATUS[] = ['Approved']
export const notCompleted: CLIENT_STATUS[] = ['Denied', 'Unknown']

/**
 * return correct client status based on kyc
 */
export const getStatus = (kycStatus?: CLIENT_STATUS): CLIENT_STATUS => kycStatus || 'REGISTERED'
export const getStatusFriendlyName = (status?: CLIENT_STATUS): string => statusFriendlyName[status as CLIENT_STATUS] || ''
export const getStatusAdminFriendlyName = (status?: CLIENT_STATUS): string => statusAdminFriendlyName[status as CLIENT_STATUS] || ''
