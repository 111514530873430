import { createAsyncThunk } from '@reduxjs/toolkit'
import { logout } from 'modules/keycloak/store/authSlice'
import { clearAccountState } from 'store/slices/accountSlice'
import { clearKycState } from 'store/slices/kycSlice'
import { AsyncThunkActionType, dispatch } from 'store/store'
import { removeLandingLoginCookie } from 'utils/landingLoginCookie'

export const logoutAndClearState: AsyncThunkActionType<{}, boolean | undefined> = createAsyncThunk(
	'logoutAndClearState',
	async (data, thunkAPI) => {
		const { rejectWithValue } = thunkAPI

		try {
			// clear cookie
			removeLandingLoginCookie()

			// clear whitelisted states (slices.ts)
			dispatch(clearAccountState())
			dispatch(clearKycState())

			dispatch(logout(data))

			return {}
		} catch (err: any) {
			return rejectWithValue('')
		}
	},
)
