import { Components } from 'api/fx/generated/client'
import { useSelector } from 'hooks/useSelector'
import {
	CRYPTO_COLOR,
	CryptoCurrency,
	DEFAULT_PRECISION,
	FIAT_COLOR,
	FIAT_RATE_DECIMAL_PRECISION,
	FiatCurrency,
} from 'modules/helpers/currencies'
import { ResponseInfo, useActionStatus } from 'modules/hooks/useActionStatus'
import { useCallback, useEffect } from 'react'
import { currenciesGet } from 'store/asyncThunks/currenciesGet'
import { dispatch } from 'store/store'

type CurrencyType = Components.Schemas.CurrencyType
type CurrencyOrCurrencyType = Components.Schemas.CurrencyOrCurrencyType

export interface ICurrenciesReturn {
	isPending: boolean
	isError: boolean
	ErrorComponent: ResponseInfo['ErrorComponent']
	FIAT: FiatCurrency[]
	CRYPTO: CryptoCurrency[]
	isCrypto: (curr?: CurrencyOrCurrencyType) => boolean
	isGroup: (curr?: CurrencyOrCurrencyType) => boolean
	getCurrencyColor: (curr?: CurrencyOrCurrencyType, isLight?: boolean) => string
	getDecimalPrecision: (curr?: CurrencyOrCurrencyType, isRate?: boolean) => number
	getSymbolName: (curr?: CurrencyOrCurrencyType) => string
	getFullName: (curr?: CurrencyOrCurrencyType) => string
}
const currencyTypeArray: CurrencyType[] = ['fiat', 'crypto']

export const useCurrencies = (): ICurrenciesReturn => {
	const {
		currencies: { FIAT, CRYPTO, list },
	} = useSelector((state) => state.currencies)
	const { isPending, isError, handleStatus, ErrorComponent } = useActionStatus()

	useEffect(() => {
		if (!FIAT.length && !CRYPTO.length) {
			handleStatus(dispatch(currenciesGet()))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [FIAT, CRYPTO]) // currencies only

	const getCurrency = useCallback((curr) => list.find((item) => item.id === curr), [list])
	const isCrypto: ICurrenciesReturn['isCrypto'] = useCallback(
		(curr) => curr?.toLowerCase() === 'crypto' || getCurrency(curr)?.type === 'crypto',
		[getCurrency],
	)
	const isGroup: ICurrenciesReturn['isGroup'] = useCallback((curr) => currencyTypeArray.includes(curr?.toLowerCase() as unknown as any), [])
	const getCurrencyColor: ICurrenciesReturn['getCurrencyColor'] = useCallback(
		(curr, light) => (isCrypto(curr) ? CRYPTO_COLOR[light ? 1 : 0] : FIAT_COLOR[light ? 1 : 0]),
		[isCrypto],
	)
	const getDecimalPrecision: ICurrenciesReturn['getDecimalPrecision'] = useCallback(
		(curr, isRate) => {
			if (isRate && !isCrypto(curr)) {
				return FIAT_RATE_DECIMAL_PRECISION
			}
			return getCurrency(curr)?.displayPrecision || DEFAULT_PRECISION
		},
		[getCurrency, isCrypto],
	)
	const getSymbolName: ICurrenciesReturn['getSymbolName'] = useCallback((curr) => getCurrency(curr)?.symbol || curr || '', [getCurrency])
	const getFullName: ICurrenciesReturn['getFullName'] = useCallback((curr) => getCurrency(curr)?.name || curr || '', [getCurrency])

	return {
		isPending,
		isError,
		ErrorComponent,
		FIAT,
		CRYPTO,
		isCrypto,
		isGroup,
		getCurrencyColor,
		getDecimalPrecision,
		getSymbolName,
		getFullName,
	}
}
