import { createSlice } from '@reduxjs/toolkit'
import { uniqBy } from 'lodash'
import { CaseReducerType } from 'store/store'
import { RoutePath } from 'utils/setBreadcrumb'

export type BreadcrumbState = RoutePath[]

const initialState: BreadcrumbState = []

type CaseReducers<State> = {
	clearBreadcrumb: CaseReducerType<State>
	updateBreadcrumb: CaseReducerType<State, State>
}

export const breadcrumbSlice = createSlice<BreadcrumbState, CaseReducers<BreadcrumbState>>({
	name: 'breadcrumb',
	initialState,
	reducers: {
		clearBreadcrumb: () => [...initialState],
		updateBreadcrumb: (state, action) => {
			return uniqBy([...state, ...action.payload], 'breadcrumb')
		},
	},
})

export const { updateBreadcrumb, clearBreadcrumb } = breadcrumbSlice.actions

export default breadcrumbSlice.reducer
