import { ENV } from 'config/env'
import { useSelector } from 'hooks/useSelector'
import { getStatus, KYC_STATUS } from 'modules/helpers/clientStatuses/clientStatuses'
import { ClientStatusIcon } from 'modules/helpers/clientStatuses/ClientStatusIcon'
import { FC } from 'react'

export const KycMenuItem: FC = () => {
	const { account } = useSelector((state) => state.account)

	return (
		<a href={ENV.KYC_STANDALONE_URL} title={'Go to KYC'} target={'_blank'} rel={'noreferrer'}>
			KYC &nbsp;
			<ClientStatusIcon status={getStatus(account?.kycProfile?.status as KYC_STATUS)} showSync />
		</a>
	)
}
