import { Components } from 'api/fx/generated/client'
import { ACCEPTED_CURRENCY } from 'modules/helpers/currencies'
import { statusesNotSuccessful } from 'modules/helpers/tradeStatuses'
import { themeColor, themeWeight } from 'modules/styles/themeStyles'
import { StatisticPricePrecision } from 'modules/ui/StatisticPricePrecision'
import { FC } from 'react'
import styled, { css } from 'styled-components'

type TradeFrontofficeResponse = Components.Schemas.TradeFrontofficeResponse

const DeclaredInfo = styled.div`
	font-size: 0.8em;
	color: ${themeColor.black};
`
const Wrapper = styled.div<{ bold: boolean }>`
	white-space: nowrap;

	${({ bold }) =>
		bold &&
		css`
			font-weight: ${themeWeight.extraBold};
		`}
`
export const InflowAmountCell: FC<{ item: TradeFrontofficeResponse }> = ({ item }) => {
	const isValueReceived = item.inflow.amount
	const isNotSuccessful = item.status && statusesNotSuccessful.includes(item.status)
	const bold = !!isValueReceived && !isNotSuccessful
	const value = isValueReceived ? item.inflow.amount : item.inflow.expectedAmount

	return (
		<Wrapper bold={bold}>
			{!isValueReceived && <DeclaredInfo>Declared amount:</DeclaredInfo>}
			<StatisticPricePrecision
				value={value}
				currency={item.inflow.currency as ACCEPTED_CURRENCY}
				suffixColor={isNotSuccessful ? themeColor.gray : undefined}
			/>
		</Wrapper>
	)
}
