import { createSlice } from '@reduxjs/toolkit'
import { Components } from 'api/fx/generated/client'
import { listToEntity } from 'modules/helpers/listToEntity'
import { fxBankAccountGet } from 'store/asyncThunks/fxBankAccountGet'
import { ObjectKeyType } from 'types'

type BankAccountResponse = Components.Schemas.BankAccountResponse

type State = {
	list: string[]
	ids: ObjectKeyType<BankAccountResponse>
}

const initialState: State = {
	list: [],
	ids: {},
}

export const bankAccountsSlice = createSlice<State, {}>({
	name: 'fxBankAccounts',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fxBankAccountGet.fulfilled, (state, action) => {
			const { ids, list } = listToEntity<BankAccountResponse>([action.payload], 'id')
			state.list = list
			state.ids = ids
		})
	},
})

export const {} = bankAccountsSlice.actions
export default bankAccountsSlice.reducer
