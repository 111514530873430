import { HASH, OrdersInProgressBox } from 'components/functional/dashboard/OrdersInProgressBox'
import { KycStatusBanner } from 'components/functional/kyc/statusBanner/KycStatusBanner'
import { MetaData } from 'components/functional/MetaData'
import { lazyComponent } from 'modules/helpers/lazyComponent'
import { PageContent } from 'modules/ui/PageContent'
import { SpaceElement } from 'modules/ui/SpaceElement'
import { WhiteBox } from 'modules/ui/WhiteBox'
import { FC, useEffect } from 'react'
import { tradesGet } from 'store/asyncThunks/tradesGet'
import { dispatch } from 'store/store'

const TradeBox = lazyComponent(() => import('components/functional/trade/dashboard/TradeBox'))

const Dashboard: FC = () => {
	// Interval update data
	useEffect(() => {
		const time = setInterval(() => {
			dispatch(tradesGet({ config: { forceUpdate: true } }))
		}, 1000 * 50)

		return () => {
			clearInterval(time)
		}
	}, [])

	return (
		<>
			<MetaData data={{ title: 'Dashboard' }} />
			<PageContent width={'xl'}>
				<KycStatusBanner />
				<WhiteBox>
					<TradeBox />
				</WhiteBox>
				<SpaceElement padding={'3%'} />
				<WhiteBox header={{ title: 'Orders in progress' }} sticky id={HASH}>
					<OrdersInProgressBox />
					<SpaceElement padding={'3%'} />
				</WhiteBox>
			</PageContent>
		</>
	)
}

export default Dashboard
