import { NoOrdersBox } from 'components/functional/dashboard/NoOrdersBox'
import TradesInProgress from 'components/functional/trade/tradesInProgress/TradesInProgress'
import { useTradeInProgressData } from 'components/functional/trade/tradesInProgress/useTradeInProgressData'
import { useActionStatus } from 'modules/hooks/useActionStatus'
import { SpinBox } from 'modules/ui/SpinBox'
import { FC, useEffect } from 'react'
import { tradesGet } from 'store/asyncThunks/tradesGet'
import { dispatch } from 'store/store'

export const HASH = 'orders-in-progress'

export const OrdersInProgressBox: FC = () => {
	const { isPending, ErrorComponent, handleStatus } = useActionStatus()
	const data = useTradeInProgressData()

	useEffect(() => {
		handleStatus(dispatch(tradesGet()))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<ErrorComponent />
			<SpinBox spinning={isPending}>
				{data.length ? <TradesInProgress data={data} /> : null}
				{!data.length && !isPending ? <NoOrdersBox /> : null}
			</SpinBox>
		</>
	)
}
