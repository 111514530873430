import { Badge } from 'antd'
import { HASH } from 'components/functional/dashboard/OrdersInProgressBox'
import { PATH } from 'components/pages/Paths'
import { useIsLoginPage } from 'hooks/useIsLoginPage'
import { useSelector } from 'hooks/useSelector'
import { filter } from 'lodash'
import { statusesInProgress } from 'modules/helpers/tradeStatuses'
import { FC, useEffect } from 'react'
import { HashLink } from 'react-router-hash-link'
import { tradesGet } from 'store/asyncThunks/tradesGet'
import { dispatch } from 'store/store'

export const MenuTradesInProgressItem: FC = () => {
	const { ids } = useSelector((state) => state.trades)
	const inProgress = filter(ids, (v) => !!(v.status && ['NEW', ...statusesInProgress].includes(v.status)))
	const isLoginPage = useIsLoginPage()

	useEffect(() => {
		!isLoginPage && dispatch(tradesGet())
	}, [isLoginPage])

	return (
		<div>
			<HashLink to={PATH.DASHBOARD.path + '#' + HASH}>Orders in progress &nbsp;</HashLink>
			{inProgress.length ? <Badge count={inProgress.length} size={'small'} /> : null}
		</div>
	)
}
