import { ENV } from 'config/env'
import { SessionResponse } from 'modules/keycloak/api/types'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const FX_LANDING_COOKIE_NAME = 'isFxLogged'

export const updateLandingLoginCookie = (res: SessionResponse): void => {
	if (ENV.LANDING_COOKIE_DOMAIN) {
		cookies.set(FX_LANDING_COOKIE_NAME, true, {
			domain: ENV.LANDING_COOKIE_DOMAIN,
			maxAge: res.expires_in,
		})
	}
}

export const removeLandingLoginCookie = (): void => {
	cookies.remove(FX_LANDING_COOKIE_NAME, {
		domain: ENV.LANDING_COOKIE_DOMAIN,
	})
}
