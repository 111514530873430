import { ENV } from 'config/env'

const gtm = () => {
	;(function (w, d, s, l, i) {
		w[l] = w[l] || []
		w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
		const f = d.getElementsByTagName('head')[0],
			j = d.createElement(s),
			dl = l != 'dataLayer' ? '&l=' + l : ''
		// @ts-ignore
		j.async = true
		// @ts-ignore
		j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
		// @ts-ignore
		f.appendChild(j)
	})(window, document, 'script', 'dataLayer', ENV.GTM_ID)
}

/**
 * GoogleSearchConsole require this script at first element in body
 */
const gtmNoScript = () => {
	const body = document.getElementsByTagName('body')[0]
	const script = document.createElement('noscript')
	const iframe = document.createElement('iframe')
	iframe.src = `https://www.googletagmanager.com/ns.html?id=${ENV.GTM_ID}`
	iframe.height = '0'
	iframe.width = '0'
	iframe.setAttribute('style', 'display:none;visibility:hidden')
	script.appendChild(iframe)

	// @ts-ignore
	body.firstElementChild.before(script)
}

export const startGtm = (): void => {
	if (localStorage.getItem('GTM_RUN') === 'OFF' || location.href.includes('GTM_RUN=OFF')) {
		console.info('GTM OFF')
	} else {
		try {
			gtm()
			gtmNoScript()
		} catch (e) {}
	}
}
