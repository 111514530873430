import { ReloadOutlined, WifiOutlined } from '@ant-design/icons'
import { Alert, Button, Result } from 'antd'
import { LAZY_TIME } from 'config/skeleton'
import { useSelector } from 'hooks/useSelector'
import { noop } from 'lodash'
import { ErrorBoundary } from 'modules/ui/ErrorBoundary'
import { SpinBox } from 'modules/ui/SpinBox'
import { ComponentType, FC, ReactElement, Suspense, useCallback, useEffect, useState } from 'react'

const defaultSpinner = <SpinBox />

type LazyStatus = 'isPending' | 'isError' | 'isSuccess'
export type ComponentWidth = 'fullWidth' | 'maxContent' | 'smallContent'
export type LazyComponentType<T extends {}> = () => Promise<{ default: ComponentType<T> }>
/**
 * Lazy loading components with Skeleton & ErrorBoundary & Internet detection
 *
 * use:
 * const MyComponent = lazyComponent(() => import('path/MyComponent'))
 * <MyComponent prop1={} />
 */
export const lazyComponent =
	<T extends {}>(imp: LazyComponentType<T>, skeleton?: ReactElement, onStatus: (status: LazyStatus) => void = noop): FC<T> =>
	(props) => {
		const [showSkeleton, setShowSkeleton] = useState<boolean>(false)
		const [Component, setComponent] = useState<any>(null)
		const [status, setStatus] = useState<LazyStatus>('isPending')
		const { isInternetConnection } = useSelector((state) => state.device)

		const loadComponent = useCallback(() => {
			setStatus('isPending')
			onStatus('isPending')
			imp()
				.then((component) => {
					setComponent(component)
					setStatus('isSuccess')
					onStatus('isSuccess')
				})
				.catch(() => {
					setStatus('isError')
					onStatus('isError')
				})
		}, [])

		// load component on start
		useEffect(() => {
			loadComponent()
		}, [loadComponent])

		// repeat load component after when internet is back
		useEffect(() => {
			status === 'isError' && isInternetConnection && loadComponent()
		}, [isInternetConnection, loadComponent, status])

		// set delay for Skeleton to avoid flickering
		useEffect(() => {
			const timer = setTimeout(() => {
				setShowSkeleton(true)
			}, LAZY_TIME)

			return () => clearTimeout(timer)
		}, [])

		return (
			<Suspense fallback={null}>
				{showSkeleton && status === 'isPending' && (skeleton || defaultSpinner)}
				{status === 'isError' && (
					<Result
						status="warning"
						title="There is problem to show this data."
						extra={
							!isInternetConnection ? (
								<Alert
									message={
										<>
											<WifiOutlined /> &nbsp; You have lost internet connection. We will load the page when connection returns.
										</>
									}
									type={'error'}
								/>
							) : (
								<Button type={'ghost'} style={{ fontWeight: 400 }} onClick={loadComponent}>
									<ReloadOutlined />
									Reload this module!
								</Button>
							)
						}
					/>
				)}
				{status === 'isSuccess' && Component && (
					<ErrorBoundary>
						<Component.default {...props} />
					</ErrorBoundary>
				)}
			</Suspense>
		)
	}
