import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { Components } from 'api/fx/generated/client'
import { messageAsyncThunk } from 'modules/helpers/messageAsyncThunk'
import { statusesAllowToCancel } from 'modules/helpers/tradeStatuses'
import { useActionStatus } from 'modules/hooks/useActionStatus'
import { useModalFn } from 'modules/hooks/useModalFn'
import { themeColor } from 'modules/styles/themeStyles'
import { ActionButton } from 'modules/table/ActionButton'
import * as React from 'react'
import { FC } from 'react'
import { tradeCancel } from 'store/asyncThunks/tradeCancel'
import { tradesGet } from 'store/asyncThunks/tradesGet'
import { dispatch } from 'store/store'
import styled from 'styled-components'
import { cyTag } from 'utils/cyTag'

type TradeFrontofficeResponse = Components.Schemas.TradeFrontofficeResponse

const CloseCircleOutlinedStyled = styled(CloseCircleOutlined)`
	color: ${themeColor.pink}!important;
`

export const CancelAction: FC<{ item: TradeFrontofficeResponse }> = ({ item }) => {
	const { isPending, handleStatus, ErrorComponent } = useActionStatus()
	const { showModal, openModal, closeModal } = useModalFn()
	const canCancel = !!(item.status && statusesAllowToCancel.includes(item.status))
	const modalRender = (node) => <div data-cy={cyTag.ordersInProgress.cancelConfirmationModalWrapper}>{node}</div>
	const onOk = (): Promise<any> =>
		item.id &&
		messageAsyncThunk(handleStatus(dispatch(tradeCancel({ params: { tradeId: item.id } }))), ['Cancelled successfully'])
			.unwrap()
			.then(() => {
				closeModal()
				dispatch(tradesGet({ config: { forceUpdate: true } }))
			})

	return canCancel ? (
		<>
			<ActionButton
				loading={isPending}
				onClick={openModal}
				icon={<CloseCircleOutlinedStyled />}
				size={'small'}
				data-cy={cyTag.ordersInProgress.cancelBtn}
			>
				Cancel
			</ActionButton>
			<Modal
				open={showModal}
				onCancel={closeModal}
				onOk={onOk}
				confirmLoading={isPending}
				title={`Cancel the order ${item.referenceNumber}`}
				width={500}
				modalRender={modalRender}
				okText={cyTag.ordersInProgress.cancelConfirmationModalBtnText}
				cancelText={'Leave unchanged'}
			>
				Confirm that you want to cancel the trade.
				<br />
				<ErrorComponent />
			</Modal>
		</>
	) : null
}
