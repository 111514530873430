import { ACCOUNT_PATH } from 'components/pages/fx/account/AccountPaths'
import { PATH } from 'components/pages/Paths'
import { GenericRoute } from 'components/pages/routeHelpers/RestrictedRoute'
import { Redirect, Switch } from 'react-router-dom'

const AccountRoutes = (
	<GenericRoute path={ACCOUNT_PATH.ACCOUNT.path} breadcrumbAction={'clear'} routePath={ACCOUNT_PATH.ACCOUNT} type={'private'}>
		<Switch>
			{/*<GenericRoute*/}
			{/*	path={ACCOUNT_PATH.INBOX.path}*/}
			{/*	routePath={ACCOUNT_PATH.INBOX}*/}
			{/*	type={'private'}*/}
			{/*	lazyImport={() => import('components/pages/fx/account/Inbox')}*/}
			{/*/>*/}
			<GenericRoute
				path={ACCOUNT_PATH.WALLETS.path}
				routePath={ACCOUNT_PATH.WALLETS}
				type={'private'}
				lazyImport={() => import('components/pages/fx/account/Wallets')}
			/>
			<GenericRoute
				path={ACCOUNT_PATH.BANK_ACCOUNTS.path}
				routePath={ACCOUNT_PATH.BANK_ACCOUNTS}
				type={'private'}
				lazyImport={() => import('components/pages/fx/account/BankAccounts')}
			/>
			<GenericRoute path={ACCOUNT_PATH.ACCOUNT.path} routePath={ACCOUNT_PATH.ACCOUNT} type={'private'}>
				<Redirect to={PATH.DASHBOARD.path} />
			</GenericRoute>
		</Switch>
	</GenericRoute>
)

export default AccountRoutes
