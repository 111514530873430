import { CloudServerOutlined } from '@ant-design/icons'
import { ENV } from 'config/env'
import { themeColor, themeWeight } from 'modules/styles/themeStyles'
import { NoWrapText } from 'modules/ui/NoWrapText'
import { FC } from 'react'
import styled from 'styled-components'

const Box = styled.div`
	font-size: 1.2em;
	font-weight: ${themeWeight.bold};
	display: inline-block;
	text-transform: uppercase;
	text-align: center;
	width: 100%;
	color: ${ENV.IS_PRODUCTION ? themeColor.pink : themeColor.black};

	.anticon {
		font-size: 1.8em;
	}
`
const InnerBox = styled.span`
	display: inline-block;
	border-bottom: 1px solid ${ENV.IS_PRODUCTION ? themeColor.pink : themeColor.black};
`

export const DevInfo: FC = () => (
	<Box>
		<InnerBox>
			<CloudServerOutlined />
			<br />
			<NoWrapText>{ENV.ENV}</NoWrapText>
		</InnerBox>
	</Box>
)
