import { Statistic } from 'antd'
import { StatisticProps } from 'antd/lib/statistic/Statistic'
import { ACCEPTED_CURRENCY } from 'modules/helpers/currencies'
import { DECIMAL_SEPARATOR, THOUSANDS_SEPARATOR } from 'modules/helpers/numberFormatter'
import { useCurrencies } from 'modules/hooks/useCurrencies'
import { ColoredCurrency } from 'modules/ui/ColoredCurrency'
import { FC } from 'react'
import styled from 'styled-components'

const StatisticStyled = styled(Statistic)`
	.ant-statistic-content-value {
		white-space: nowrap;
	}
	&.suffix-new-line {
		.ant-statistic-content-suffix {
			display: block;
			margin: 0;
		}
	}
`

interface IProps {
	value: number | undefined | ''
	currency: ACCEPTED_CURRENCY
	showSuffix?: boolean
	suffixNewLine?: boolean
	suffixColor?: string
	// isRate - if true set different precision
	isRate?: boolean
}
export const StatisticPricePrecision: FC<StatisticProps & IProps> = ({
	value,
	currency,
	showSuffix = true,
	suffixNewLine,
	suffixColor,
	isRate = false,
	...params
}) => {
	const { getDecimalPrecision } = useCurrencies()
	const precision = getDecimalPrecision(currency, isRate)
	// toFixed because number could be short eg:8e-7 (scientific notation)
	const scientific = value ? value.toFixed(precision) : value

	return (
		<StatisticStyled
			className={suffixNewLine ? 'suffix-new-line' : ''}
			value={scientific}
			suffix={showSuffix && <ColoredCurrency currency={currency} color={suffixColor} />}
			groupSeparator={THOUSANDS_SEPARATOR}
			precision={precision}
			decimalSeparator={DECIMAL_SEPARATOR}
			{...params}
		/>
	)
}
