import { createSlice } from '@reduxjs/toolkit'
import { Components } from 'api/fx/generated/client'
import { listToEntity } from 'modules/helpers/listToEntity'
import { walletCreate } from 'store/asyncThunks/walletCreate'
import { walletsGet } from 'store/asyncThunks/walletsGet'
import { walletUpdate } from 'store/asyncThunks/walletUpdate'
import { ObjectKeyType } from 'types'

type WalletResponse = Components.Schemas.WalletAddressResponse

type State = {
	list: string[]
	ids: ObjectKeyType<WalletResponse>
}

const initialState: State = {
	list: [],
	ids: {},
}

export const walletsSlice = createSlice<State, {}>({
	name: 'wallets',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(walletCreate.fulfilled, (state, action) => {
			if (action.payload.id) {
				state.list.push(action.payload.id)
				state.ids[action.payload.id] = action.payload
			}
		})
		builder.addCase(walletsGet.fulfilled, (state, action) => {
			const { ids, list } = listToEntity<WalletResponse>(action.payload, 'id')
			state.list = list
			state.ids = ids
		})
		builder.addCase(walletUpdate.fulfilled, (state, action) => {
			if (action.payload.id) {
				state.ids[action.payload.id] = action.payload
			}
		})
	},
})

export const {} = walletsSlice.actions
export default walletsSlice.reducer
