import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncThunkDataType } from 'store/common'
import type { AppThunkType } from 'store/store'
import { IRefreshTokenParams, refreshToken as refreshTokenRequest } from '../api/keycloakActions'
import { SessionResponse } from '../api/types'

export const refreshToken = createAsyncThunk<
	SessionResponse,
	AsyncThunkDataType<Pick<IRefreshTokenParams, 'keycloakDomain'>, null, 'params'>,
	AppThunkType
>('keycloak/refreshToken', async ({ params }, thunkAPI) => {
	const { rejectWithValue, getState } = thunkAPI

	try {
		const token = getState().auth.session?.refresh_token
		if (!token) {
			return rejectWithValue('')
		}

		const response = await refreshTokenRequest({ ...params, refreshToken: token })

		return response.data
	} catch (err: any) {
		return rejectWithValue('')
	}
})
