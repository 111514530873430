import { Result } from 'antd'
import { WhiteBox } from 'modules/ui/WhiteBox'
import React from 'react'

/**
 * https://pl.reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component {
	constructor(props: object) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(): { hasError: boolean } {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(): void {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo)
	}

	render(): React.ReactNode {
		// @ts-ignore
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<WhiteBox>
					<Result status="warning" title="There is problem to show this module." subTitle={'Please contact with Bitclear AG'} />
				</WhiteBox>
			)
		}

		return this.props.children
	}
}
