import { Col, Collapse, Row } from 'antd'
import { KycInfo } from 'assets/icon'
import { IKycStatusContentProps, KycStatusBannerContent } from 'components/functional/kyc/statusBanner/KycStatusBannerContent'
import { useUserData } from 'hooks/useUserData'
import { themeWeight } from 'modules/styles/themeStyles'
import { SpaceElement } from 'modules/ui/SpaceElement'
import { WhiteBox } from 'modules/ui/WhiteBox'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

const Box = styled.div`
	.ant-collapse-header {
		display: none !important;
	}
	.ant-collapse-content-box {
		padding: 0 !important;
	}
`
const Title = styled.div`
	font-size: 1.2em;
	font-weight: ${themeWeight.bold};
`
const Wrapper = styled.div`
	svg {
		height: 120px;
	}
`

type IProps = IKycStatusContentProps
export const KycStatusBanner: FC<IProps> = ({ ...props }) => {
	const [show, setShow] = useState<boolean>(false)
	const { userName, companyName, account, isKycCompleted } = useUserData()
	const isKycInitialised = !!account?.kycProfile
	const isKycNotCompleted = !isKycCompleted

	useEffect(() => {
		if (isKycInitialised && isKycNotCompleted) {
			!show &&
				setTimeout(() => {
					setShow(true)
				}, 2000)
		} else {
			show && setShow(false)
		}
	}, [isKycInitialised, isKycNotCompleted, show])

	return (
		<Box>
			<Collapse activeKey={show ? 1 : undefined} bordered={false} ghost>
				<Collapse.Panel key="1" header={null} showArrow={false}>
					<WhiteBox>
						<Wrapper>
							<Title>Dear {userName || companyName},</Title>
							<Row gutter={16} justify={'space-between'}>
								<Col>
									<br />
									<KycStatusBannerContent {...props} />
								</Col>
								<Col>
									<KycInfo />
								</Col>
							</Row>
						</Wrapper>
					</WhiteBox>
					<SpaceElement padding={'3%'} />
				</Collapse.Panel>
			</Collapse>
		</Box>
	)
}
