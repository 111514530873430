import { useSelector } from 'hooks/useSelector'
import { noop } from 'lodash'
import { KEYCLOAK_LOGIN_URL } from 'modules/keycloak/api/keycloakActions'
import { SessionResponse } from 'modules/keycloak/api/types'
import { getToken } from 'modules/keycloak/store/getToken'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { dispatch } from 'store/store'
import { setBackToUri } from '../store/authSlice'
import { useIsUserLogged } from './useIsUserLogged'

interface IProps {
	loginPagePath: string
	homePath: string
	keycloakDomain?: string
	onLoginSuccess?: (res: SessionResponse) => void
}

/**
 * Create login component and run it there
 *
 * @param homePath - home app path url to redirect after login success (if backToUri not exist)
 * @param loginPagePath - path url where is used this hook
 * @param keycloakDomain
 * @param onLoginSuccess
 */
export const useKeycloakLoginPage = ({ loginPagePath, keycloakDomain, homePath, onLoginSuccess = noop }: IProps): void => {
	const history = useHistory()
	const { isUserLogged } = useIsUserLogged()
	const { backToUri, lastAuthCode } = useSelector((state) => state.auth)
	const query = new URLSearchParams(location.search)
	const code = query.get('code')

	const redirectToAuth = useCallback(() => {
		if (keycloakDomain) {
			window.location.href = keycloakDomain + KEYCLOAK_LOGIN_URL + loginPagePath
		}
	}, [keycloakDomain, loginPagePath])

	/**
	 * read session code and getToken
	 * redirect to keycloak otherwise
	 */
	useEffect(() => {
		// auth code has to be uniq (usable only once)
		if (code && code !== lastAuthCode) {
			keycloakDomain &&
				dispatch(getToken({ params: { keycloakDomain, code, redirect_uri: loginPagePath } }))
					.unwrap()
					.then((res) => onLoginSuccess(res.data))
					.catch(redirectToAuth)
		} else {
			// prevent redirection during logging process
			if (!isUserLogged) {
				redirectToAuth()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code]) // only code

	/**
	 * getToken success - redirect to user page
	 */
	useEffect(() => {
		if (isUserLogged) {
			history.replace(backToUri || homePath)
			setTimeout(() => dispatch(setBackToUri('')), 1000) // timeout to prevent refresh Login component
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserLogged]) // only isUserLogged
}
