import { createSlice } from '@reduxjs/toolkit'
import { Components } from 'api/fx/generated/client'
import { listToEntity } from 'modules/helpers/listToEntity'
import { tradeCreate } from 'store/asyncThunks/tradeCreate'
import { tradeEventsGet } from 'store/asyncThunks/tradeEventsGet'
import { tradesGet } from 'store/asyncThunks/tradesGet'
import { ObjectKeyType } from 'types'

type TradeEventFrontofficeResponse = Components.Schemas.TradeEventFrontofficeResponse
export type TradeResponse = Components.Schemas.TradeFrontofficeResponse

type State = {
	list: string[]
	ids: ObjectKeyType<TradeResponse>
	eventsIds: ObjectKeyType<TradeEventFrontofficeResponse[]>
}

const initialState: State = {
	list: [],
	ids: {},
	eventsIds: {},
}

export const tradesSlice = createSlice<State, {}>({
	name: 'trades',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(tradeCreate.fulfilled, (state, action) => {
			if (action.payload.id) {
				state.list.push(action.payload.id)
				state.ids[action.payload.id] = action.payload
			}
		})
		builder.addCase(tradesGet.fulfilled, (state, action) => {
			const { ids, list } = listToEntity<TradeResponse>(action.payload, 'id')
			state.list = list
			state.ids = ids
		})
		builder.addCase(tradeEventsGet.fulfilled, (state, action) => {
			const { id, data } = action.payload
			state.eventsIds[id] = data
		})
	},
})

export const {} = tradesSlice.actions
export default tradesSlice.reducer
