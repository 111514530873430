import { AxiosError } from 'axios'

export const errorNetworkInterceptor = (error: AxiosError): Promise<any> => {
	if (!error || !error?.response) {
		return Promise.reject({
			code: error?.code,
			response: {
				data: {
					message: "Can't sent request. Network error occurs.",
				},
				status: 408,
				error: error,
			},
		})
	} else if (!error || error?.code === 'ECONNABORTED') {
		return Promise.reject({
			code: error?.code,
			response: {
				data: {
					message: "Can't sent request. Network error occurs.",
					...error?.response?.data,
				},
				status: 408,
				error: error,
			},
		})
	}

	return Promise.reject(error)
}
