import { UnlockOutlined } from '@ant-design/icons'
import { FC, useCallback } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	border-top: 1px solid darkseagreen;
	border-right: 1px solid darkseagreen;
	border-bottom: 1px solid green;
	border-left: 1px solid green;
	padding: 3px 3px 3px 5px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	box-shadow: -1px 1px 5px -1px darkseagreen;
`

export const isSuperAdmin = (): boolean => localStorage.isSuperAdmin === 'true'

export const SuperAdminOnly: FC = ({ children }) => {
	const canShow = useCallback(isSuperAdmin, [])

	return canShow() ? (
		<Wrapper>
			<UnlockOutlined style={{ color: 'green' }} />
			&nbsp;&nbsp;
			<div>{children}</div>
		</Wrapper>
	) : null
}
