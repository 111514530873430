import { noop } from 'lodash'
import { SessionResponse } from 'modules/keycloak/api/types'
import { useCallback, useEffect } from 'react'
import { dispatch, store } from 'store/store'
import { logout } from '../store/authSlice'
import { refreshToken as keycloakRefreshToken } from '../store/refreshToken'

let refreshTimeout

interface IProps {
	keycloakDomain: string
	onRefreshSuccess?: (res: SessionResponse) => void
	onRefreshError?: () => void
}

/**
 * Interval checking if token is actual
 * Run it in Root component (only once in app)
 *
 * Do not use here useSelector or any other fns which are using it, to avoid refresh all page every time
 */
export const useKeycloakRefreshToken = ({
	keycloakDomain,
	onRefreshSuccess = noop,
	onRefreshError = () => dispatch(logout()),
}: IProps): void => {
	/**
	 * refresh token interval
	 */
	const refreshToken = useCallback((skipRefreshing = false) => {
		// wait 1800s if there is not a session yet - default value in keycloak
		const expires = store.getState().auth.session?.expires_in || 1800

		const ms = expires * 700 // ms to refresh some % before time will end
		clearTimeout(refreshTimeout)
		refreshTimeout = setTimeout(refreshToken, ms)

		if (!skipRefreshing && store.getState().auth.session?.access_token) {
			dispatch(keycloakRefreshToken({ params: { keycloakDomain } }))
				.unwrap()
				.then(onRefreshSuccess)
				.catch(() => {
					onRefreshError()
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // nothing here

	/**
	 * start interval after login
	 */
	useEffect(() => {
		refreshToken(true)
	}, [refreshToken])
}
